import React from "react"
import classnames from "classnames"
import { RouteComponentProps } from "@reach/router"
import { connect, ConnectedProps } from "react-redux"
import { navigate, Link } from "gatsby"
import reactStringReplace from "react-string-replace"

import { store } from "@/types/interface"
import { find } from "../../actions/course"
import { Slider, Image, SEO } from "../../components/common"
import { DetailPanel, TrialPanel } from "../../components/course-detail"
import { useWindowSize } from "../../utils/useWindowSize"
import styles from "./course-detail.module.css"

const connector = connect(
  // mapStateToProps
  ({ course }: { course: store.Course }) => ({
    courseDetail: course?.courseDetail,
  }),
  // mapDispatchToProps
  { find }
)

type Props = ContainerProps & {
  targetRef: React.RefObject<HTMLDivElement>
  dimensions: {
    width: number
    height: number
  }
  size: ReturnType<typeof useWindowSize>
}

const CourseDetail: React.FC<Props> = props => {
  const linkRegex = /(https?:\/\/\S+)/g
  const isRemote = props.courseDetail?.trials.some(trial => {
    return Boolean(trial.peatixLink)
  })

  return (
    <>
      <SEO
        title={`Culture House ${props.courseDetail?.courseTitle}`}
        description={props.courseDetail?.description}
      />
      <h1 className={styles.title}>{props.courseDetail?.courseTitle}</h1>
      <div className={styles.headContainer}>
        <div className={styles.dateWrapper}>
          {props.courseDetail?.dateDisplays.map((date, i) => {
            return <p key={i}>{date}</p>
          })}
        </div>
        <div className={styles.timeWrapper}>
          {props.courseDetail?.times.map((time, i) => {
            return <p key={i}>{time}</p>
          })}
        </div>
        {!isRemote ? (
          <Link
            to={`/form/regular/${props.courseDetail?._id}`}
            className={styles.proposal}
          >
            一日体験
            <br />
            申し込み
          </Link>
        ) : (
          <Link
            to="/online-guide"
            className={classnames(styles.proposal, styles.onlineGuide)}
          >
            <p>
              オンライン
              <br />
              講座ガイド
            </p>
          </Link>
        )}
      </div>
      {props.courseDetail && !props.courseDetail?.hasMultipleTeachers && (
        <p className={styles.teacher}>{getTeacherName(props.courseDetail)}</p>
      )}
      <div className={styles.topContainer}>
        <div className={styles.sliderContainer} ref={props.targetRef}>
          {props.dimensions.width != 0 &&
          props.courseDetail?.imagePaths.length !== 0 ? (
            <Slider
              key={props.courseDetail?._id}
              slideWidth={props.dimensions.width}
              slideHeight={props.dimensions.height}
              photos={
                props.courseDetail?.imagePaths
                  ? props.courseDetail.imagePaths
                  : []
              }
              isStatic={false}
              hasSelector={
                props.size.width && props.size.width < 768 ? false : true
              }
            />
          ) : (
            <Image
              filename="no-photo.jpg"
              width={props.dimensions.width}
              height={props.dimensions.height}
            />
          )}
        </div>
        <p className={styles.description}>
          {reactStringReplace(
            props.courseDetail?.description,
            linkRegex,
            (match, i) => {
              const youtubeMatch = match.match(/https:\/\/(?:www.)?youtube.com\/watch\?v=(.*)/)
              return youtubeMatch ? (
                <div key={i} className={styles.youtubeWrapper}>
                  <div className={styles.youtube}>
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube-nocookie.com/embed/${youtubeMatch[1]}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              ) : (
                <a
                  key={i}
                  className={styles.linkText}
                  href={match}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {match}
                </a>
              )
            }
          )}
        </p>
      </div>
      {props.courseDetail && (
        <DetailPanel
          key={props.courseDetail._id}
          courseDetail={props.courseDetail}
        />
      )}
      {props.courseDetail && props.courseDetail.trials.length > 0 ? (
        <TrialPanel trials={props.courseDetail.trials} />
      ) : null}
      {!isRemote ? (
        <Link
          to={`/form/regular/${props.courseDetail?._id}`}
          className={`${styles.proposal} ${styles.footer}`}
        >
          <p>一日体験申し込み</p>
        </Link>
      ) : (
        <Link
          to="/online-guide"
          className={`${styles.proposal} ${styles.footer} ${styles.onlineGuide}`}
        >
          <p>オンライン講座ガイド</p>
        </Link>
      )}
    </>
  )
}

const getTeacherName = (
  courseDetail: NonNullable<store.Course["courseDetail"]>
): string => {
  return courseDetail?.teacherTitles && courseDetail.teacherTitles[0]
    ? `${courseDetail.teacherTitles[0]}\n${courseDetail.teacherNames[0]}`
    : courseDetail.teacherNames[0]
}

type ContainerProps = RouteComponentProps<{ courseId: string }> &
  ConnectedProps<typeof connector>

const Container: React.FC<ContainerProps> = props => {
  const targetRef = React.useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 })
  const size = useWindowSize()

  React.useEffect(() => {
    // get course detail information
    if (props.courseId) {
      props.find(props.courseId)
    } else {
      navigate("/")
    }
  }, [])

  React.useEffect(() => {
    // get target ref dimensions
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
  }, [size])

  return (
    <CourseDetail
      {...props}
      targetRef={targetRef}
      dimensions={dimensions}
      size={size}
    />
  )
}

export default connector(Container)
