import React from "react"

import { store } from "@/types/interface"
import styles from "./trial-panel.module.css"

type Props = {
  trials: NonNullable<store.Course["courseDetail"]>["trials"]
}

const TrialPanel: React.FC<Props> = ({ trials }) => {
  return (
    <div className={styles.panelContainer}>
      <h2 className={styles.onedayTrial}>一日体験</h2>
      <p>
        ※1日体験は下記日程以外にも、毎月講座設定日に実施しております。お気軽にお申し込み下さい。
      </p>
      {trials.map(trial => {
        return (
          <div key={trial._id} className={styles.trialWrapper}>
            <h3>{trial.title}</h3>
            {trial.dateDisplays.map((_, i) => (
              <p
                key={i}
              >{`${trial.dateDisplays[i]}/${trial.timeDisplays[i]}`}</p>
            ))}
            <p>参加費：{trial.fee === 0 ? "無料(要予約)" : `${trial.fee}円`}</p>
            <p>{trial.description}</p>
            {trial.peatixLink && (
              <a className={styles.onlinePay} href={trial.peatixLink} target="_blank">
                一日体験申し込み
                <br />
                (オンライン決済)
              </a>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default TrialPanel
