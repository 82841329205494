import React from "react"

import { Accordion, Image } from "../common"
import { store } from "@/types/interface"
import styles from "./detail-panel.module.css"

type Props = {
  courseDetail: NonNullable<store.Course["courseDetail"]>
}

const OnePanel: React.FC<Props> = ({ courseDetail }) => {
  return (
    <div className={styles.panelContainer}>
      <div className={styles.subTitle}>
        <Image className={styles.icon} filename="watch.png" />
        <h3 className={styles.date}>日時</h3>
      </div>
      <p>
        {courseDetail.dateDisplays[0]} {courseDetail.times[0]}
      </p>
      <div className={styles.subTitle}>
        <Image className={styles.icon} filename="money.png" />
        <h3 className={styles.fee}>参加費</h3>
      </div>
      <p>{`${courseDetail.lessonTerms[0]} ${courseDetail.fees[0].toLocaleString()}円`}</p>
      {courseDetail.belongings[0] && (
        <>
          <div className={styles.subTitle}>
            <Image className={styles.icon} filename="belongings.png" />
            <h3 className={styles.belongings}>持ち物</h3>
          </div>
          <p>{courseDetail.belongings[0]}</p>
        </>
      )}
    </div>
  )
}

const MultiPanels: React.FC<Props> = ({ courseDetail }) => {
  return (
    <>
      {[...Array(courseDetail.lessonTitles.length)].map((_, i) => {
        return (
          <Accordion
            key={i}
            label={`${courseDetail.lessonTitles[i]}\n${courseDetail.dateDisplays[i]} ${courseDetail.times[i]}`}
          >
            <div className={styles.panelContainer}>
              {courseDetail.hasMultipleTeachers && (
                <>
                  <div className={styles.subTitle}>
                    <Image className={styles.icon} filename="teacher.png" />
                    <h3 className={styles.teacher}>講師</h3>
                  </div>
                  <p>
                    {courseDetail.teacherTitles &&
                      courseDetail.teacherTitles[i] &&
                      `${courseDetail.teacherTitles[i]}\n`}
                    {courseDetail.teacherNames[i]}
                  </p>
                </>
              )}
              <div className={styles.subTitle}>
                <Image className={styles.icon} filename="watch.png" />
                <h3 className={styles.date}>日時</h3>
              </div>
              <p>
                {courseDetail.dateDisplays[i]} {courseDetail.times[i]}
              </p>
              <div className={styles.subTitle}>
                <Image className={styles.icon} filename="money.png" />
                <h3 className={styles.fee}>参加費</h3>
              </div>
              <p>
                {`${courseDetail.lessonTerms[i]} ${courseDetail.fees[i].toLocaleString()}円`}
              </p>
              {courseDetail.belongings[i] && (
                <>
                  <div className={styles.subTitle}>
                    <Image className={styles.icon} filename="belongings.png" />
                    <h3 className={styles.belongings}>持ち物</h3>
                  </div>
                  <p>{courseDetail.belongings[i]}</p>
                </>
              )}
            </div>
          </Accordion>
        )
      })}
    </>
  )
}

const DetailPanel: React.FC<Props> = ({ courseDetail }) => {
  return (
    <>
      {courseDetail.lessonTitles.length > 1 ? (
        <MultiPanels courseDetail={courseDetail} />
      ) : (
        <OnePanel courseDetail={courseDetail} />
      )}
    </>
  )
}

export default DetailPanel
