import React from "react"
import { Router } from "@reach/router"
import { withPrefix } from "gatsby"

import { Layout } from "../../components"
import CourseDetail from "./course-detail"

const Index: React.FC = () => {
  return (
    <Layout>
      <div className="container">
        <Router basepath={withPrefix("course")}>
          <CourseDetail path="/:courseId" />
        </Router>
      </div>
    </Layout>
  )
}

export default Index
